import { store } from "@/store";
import Axios from "axios";
import { generateUrl, generateHeader } from "./http-common";

const getDownloadUrl = (type: statementType) => {
  if (type === "payments")
    return `organisations/${store.state.shops.activeShop.id}/transactions/statements/download`;
  else
    return `orders/statement/download?supplier_org_id=${store.state.shops.activeShop.id}&salesOrder=true`;
};

export type format = "pdf" | "csv" | "xlsx";
export type statementType = "orders" | "payments";

// ORDERS
async function downloadOrderStatement(
  from_date: string,
  to_date: string,
  format: format = "pdf",
  statementType: statementType
) {
  Axios.get(generateUrl(getDownloadUrl(statementType)), {
    params: {
      format,
      from_date,
      to_date,
    },
    headers: await generateHeader(),
    responseType: "blob",
  })
    .then((response) => {
      const data = window.URL.createObjectURL(new Blob([response.data]));
      const link = document.createElement("a");
      link.href = data;
      const file =
        response.headers["content-disposition"].split("filename=")[1];
      const filename = file.replace('"', "").split(`.${format}`)[0];
      link.setAttribute("download", `${filename}.${format}`);
      link.click();
      store.dispatch(
        "notifications/success",
        "File downloaded, please check your download folder.",
        { root: true }
      );
    })
    .catch((error) => {
      store.dispatch("notifications/error", "Failed to download file", {
        root: true,
      });
    });
}

export function downloadToday(statementType: statementType, format?: format) {
  const nowDate = new Date();
  nowDate.setDate(nowDate.getDate());
  const fromDate = new Date(nowDate).toLocaleDateString("en-CA");
  const toDate = fromDate;

  downloadOrderStatement(fromDate, toDate, format, statementType);
}

export function downloadThisWeek(
  statementType: statementType,
  format?: format
) {
  const d = new Date();

  // set days of this week
  const thisWeekMonday = new Date(
    d.setDate(d.getDate() - ((d.getDay() + 6) % 7))
  ).toLocaleDateString("en-CA");
  const thisWeekSunday = new Date(
    d.setDate(d.getDate() + 6)
  ).toLocaleDateString("en-CA");

  downloadOrderStatement(thisWeekMonday, thisWeekSunday, format, statementType);
}

export function downloadThisMonth(
  statementType: statementType,
  format?: format
) {
  const date = new Date();
  const firstDay = new Date(
    date.getFullYear(),
    date.getMonth(),
    1
  ).toLocaleDateString("en-CA");
  const lastDay = new Date(
    date.getFullYear(),
    date.getMonth() + 1,
    0
  ).toLocaleDateString("en-CA");

  downloadOrderStatement(firstDay, lastDay, format, statementType);
}

export function downloadYesterday(
  statementType: statementType,
  format?: format
) {
  const nowDate = new Date();
  nowDate.setDate(nowDate.getDate() - 1);
  const fromDate = new Date(nowDate).toLocaleDateString("en-CA");
  const toDate = fromDate;

  downloadOrderStatement(fromDate, toDate, format, statementType);
}

export function downloadLastWeek(
  statementType: statementType,
  format?: format
) {
  const d = new Date();

  // set to Monday of this week
  d.setDate(d.getDate() - ((d.getDay() + 6) % 7));

  // set to previous Monday
  const lastWeekMonday = new Date(
    d.setDate(d.getDate() - 7)
  ).toLocaleDateString("en-CA");
  const lastWeekSunday = new Date(
    d.setDate(d.getDate() + 6)
  ).toLocaleDateString("en-CA");

  downloadOrderStatement(lastWeekMonday, lastWeekSunday, format, statementType);
}

export function downloadLastMonth(
  statementType: statementType,
  format?: format
) {
  const date = new Date();
  const firstDay = new Date(date.getFullYear(), date.getMonth() - 1, 1);
  const lastDay = new Date(date.getFullYear(), date.getMonth(), 0);

  downloadOrderStatement(
    new Date(firstDay).toLocaleDateString("en-CA"),
    new Date(lastDay).toLocaleDateString("en-CA"),
    format,
    statementType
  );
}

export function downloadCustomRange(
  fromDate: string,
  toDate: string,
  statementType: statementType,
  format?: format
) {
  downloadOrderStatement(fromDate, toDate, format, statementType);
}

// PAYMENTS
async function downloadPaymentStatement(
  from_date: string,
  to_date: string,
  format: format = "xlsx",
  statementType: statementType
) {
  Axios.get(generateUrl(getDownloadUrl(statementType)), {
    params: {
      format,
      from_date,
      to_date,
    },
    headers: await generateHeader(),
    responseType: "blob",
  })
    .then((response) => {
      const data = window.URL.createObjectURL(new Blob([response.data]));
      const link = document.createElement("a");
      link.href = data;
      const file =
        response.headers["content-disposition"].split("filename=")[1];
      const filename = file.replace('"', "").split(`.${format}`)[0];
      link.setAttribute("download", `${filename}.${format}`);
      link.click();
      store.dispatch(
        "notifications/success",
        "File downloaded, please check your download folder.",
        { root: true }
      );
    })
    .catch((error) => {
      store.dispatch("notifications/error", "Failed to download file", {
        root: true,
      });
    });
}

export function downloadTodayPayments(
  statementType: statementType,
  format?: format
) {
  const nowDate = new Date();
  nowDate.setDate(nowDate.getDate());
  const fromDate = new Date(nowDate).toLocaleDateString("en-CA");
  const toDate = fromDate;

  downloadPaymentStatement(fromDate, toDate, format, statementType);
}

export function downloadThisWeekPayments(
  statementType: statementType,
  format?: format
) {
  const d = new Date();

  // set days of this week
  const thisWeekMonday = new Date(
    d.setDate(d.getDate() - ((d.getDay() + 6) % 7))
  ).toLocaleDateString("en-CA");
  const thisWeekSunday = new Date(
    d.setDate(d.getDate() + 6)
  ).toLocaleDateString("en-CA");

  downloadPaymentStatement(
    thisWeekMonday,
    thisWeekSunday,
    format,
    statementType
  );
}

export function downloadThisMonthPayments(
  statementType: statementType,
  format?: format
) {
  const date = new Date();
  const firstDay = new Date(
    date.getFullYear(),
    date.getMonth(),
    1
  ).toLocaleDateString("en-CA");
  const lastDay = new Date(
    date.getFullYear(),
    date.getMonth() + 1,
    0
  ).toLocaleDateString("en-CA");

  downloadPaymentStatement(firstDay, lastDay, format, statementType);
}

export function downloadYesterdayPayments(
  statementType: statementType,
  format?: format
) {
  const nowDate = new Date();
  nowDate.setDate(nowDate.getDate() - 1);
  const fromDate = new Date(nowDate).toLocaleDateString("en-CA");
  const toDate = fromDate;

  downloadPaymentStatement(fromDate, toDate, format, statementType);
}

export function downloadLastWeekPayments(
  statementType: statementType,
  format?: format
) {
  const d = new Date();

  // set to Monday of this week
  d.setDate(d.getDate() - ((d.getDay() + 6) % 7));

  // set to previous Monday
  const lastWeekMonday = new Date(
    d.setDate(d.getDate() - 7)
  ).toLocaleDateString("en-CA");
  const lastWeekSunday = new Date(
    d.setDate(d.getDate() + 6)
  ).toLocaleDateString("en-CA");

  downloadPaymentStatement(
    lastWeekMonday,
    lastWeekSunday,
    format,
    statementType
  );
}

export function downloadLastMonthPayments(
  statementType: statementType,
  format?: format
) {
  const date = new Date();
  const firstDay = new Date(date.getFullYear(), date.getMonth() - 1, 1);
  const lastDay: any = new Date(date.getFullYear(), date.getMonth(), 0);

  downloadPaymentStatement(
    new Date(firstDay).toLocaleDateString("en-CA"),
    new Date(lastDay).toLocaleDateString("en-CA"),
    format,
    statementType
  );
}

export function downloadCustomRangePayments(
  fromDate: string,
  toDate: string,
  statementType: statementType,
  format?: format
) {
  downloadPaymentStatement(fromDate, toDate, format, statementType);
}
