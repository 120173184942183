<template>
  <S2SForm title="Orders Statements">
    <v-card class="mb-3">
      <v-card-title>
        <div class="subheading">Download Statements</div>
      </v-card-title>
      <v-divider></v-divider>
      <v-container grid-list-xs>
        <v-layout row wrap>
          <v-flex sm6 xs12 pr-3>
            <v-select
              :items="dateItems"
              label="Statement for"
              item-text="label"
              return-object
              v-model="statementAction"
            ></v-select>
          </v-flex>
          <v-flex sm3 xs12 pr-3>
            <v-select
              :items="downloadType"
              label="Download as"
              item-text="label"
              item-value="value"
              v-model="statementType"
            ></v-select>
          </v-flex>
          <v-flex sm3 xs12 class="text-xs-left" d-flex align-center>
            <v-btn
              color="accent"
              :block="$vuetify.breakpoint.xsOnly"
              :disabled="!statementAction || !statementType"
              @click="statementAction.action('orders', statementType)"
              >Download</v-btn
            >
          </v-flex>
        </v-layout>
      </v-container>
    </v-card>
    <v-card>
      <v-card-title>
        <div class="subheading">Custom Date Ranges</div>
      </v-card-title>
      <v-divider></v-divider>
      <v-container grid-list-xs>
        <v-layout row wrap>
          <v-flex sm3 xs12 pr-4>
            <v-text-field
              @click="dialogModal = true"
              label="From Date"
              prepend-icon="event"
              :value="dates.fromDate"
              readonly
            ></v-text-field>
          </v-flex>
          <v-flex sm3 xs12 pr-4>
            <v-text-field
              @click="dialogModal = true"
              label="To Date"
              prepend-icon="event"
              :value="dates.toDate"
              readonly
            ></v-text-field>
          </v-flex>
          <v-flex sm3 xs12 pr-4>
            <v-select
              :items="downloadType"
              label="Download as"
              item-text="label"
              item-value="value"
              v-model="customStatementType"
            ></v-select>
          </v-flex>
          <v-flex sm3 xs12 class="text-xs-left" d-flex align-center>
            <v-btn
              color="accent"
              :block="$vuetify.breakpoint.xsOnly"
              :disabled="!dates.fromDate || !dates.toDate"
              @click="
                downloadCustomRange(
                  dates.fromDate,
                  dates.toDate,
                  'orders',
                  customStatementType
                )
              "
              >Download</v-btn
            >
          </v-flex>
        </v-layout>
      </v-container>
    </v-card>
    <v-dialog ref="dialog" v-model="dialogModal" persistent width="initial">
      <div>
        <v-layout row wrap class="white" px-2 pb-2>
          <v-flex mr-2>
            <h4 style="background-color: white" class="py-2 pl-2">
              From Date: {{ dates.fromDate }}
            </h4>
            <v-date-picker
              v-model="dates.fromDate"
              type="date"
              scrollable
              no-title
              :max="dates.toDate"
            ></v-date-picker>
          </v-flex>
          <v-flex>
            <h4 style="background-color: white" class="py-2 pl-2">
              To Date: {{ dates.toDate }}
            </h4>
            <v-date-picker
              v-model="dates.toDate"
              type="date"
              scrollable
              no-title
              :min="dates.fromDate"
            ></v-date-picker>
          </v-flex>
        </v-layout>

        <v-flex>
          <v-card color="background" height="50px">
            <v-btn
              text
              color="primary"
              @click="dialogModal = false"
              block
              min-height="100%"
              >Done</v-btn
            >
          </v-card>
        </v-flex>
      </div>
    </v-dialog>
  </S2SForm>
</template>

<script>
  import Vue from "vue";
  import {
    downloadYesterday,
    downloadToday,
    downloadThisWeek,
    downloadThisMonth,
    downloadLastWeek,
    downloadLastMonth,
    downloadCustomRange,
  } from "@/api/statements";

  export default Vue.extend({
    name: "DownloadStatements",

    data: function () {
      return {
        downloadCustomRange,
        dateItems: [
          { label: "Today", action: downloadToday, id: 0 },
          { label: "This Week", action: downloadThisWeek, id: 1 },
          { label: "This Month", action: downloadThisMonth, id: 2 },
          { label: "Yesterday", action: downloadYesterday, id: 3 },
          { label: "Last Week", action: downloadLastWeek, id: 4 },
          { label: "Last Month", action: downloadLastMonth, id: 5 },
        ],
        dates: {},
        downloadType: [
          { label: "PDF", value: "pdf" },
          { label: "CSV", value: "csv" },
        ],
        customDownloadType: [
          { label: "PDF", value: "pdf" },
          { label: "CSV", value: "csv" },
        ],
        dialogModal: false,

        statementAction: null,
        statementType: "pdf",
        customStatementType: "pdf",
      };
    },
  });
</script>
